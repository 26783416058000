import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import { Paper, CssBaseline, Divider, Box, InputBase, Dialog, DialogTitle, DialogContent, IconButton, useMediaQuery } from '@material-ui/core';
import AOS from 'aos';

import theme from './theme';
import Routes from './Routes';

import store from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'aos/dist/aos.css';
import { Typography } from '@material-ui/core';
import Graficas from 'components/test/Graficas';
import Scene3D from 'components/Scene3D';
import { TransitionUp } from 'components/TransitionComponents';
import { CloseOutlined } from '@material-ui/icons';

const browserHistory = createBrowserHistory();

browserHistory.listen(location => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (location.action === 'POP') {
      return;
    }
    // In all other cases, scroll to top
    window.scrollTo(0, 0);
  });
});

const App = () => {
  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out',
  });
  const [showDemo, setShowDemo] = useState(false);
  const isMd = window.screen.width > window.screen.height;
  let isAllow = false;
  const l = window.location.toString();
  if (l.endsWith('promociones-mes') || l.endsWith('sucursales') || l.startsWith('http://localhost:')) {
    isAllow = true;
  } else {
    isAllow = true;
  }
  console.log('window.location.toString()', window.location.toString())
  const [state, setstate] = useState({ banner: { show: true } });
  let persistor = persistStore(store);
  // return (
  //   <Box position={'fixed'} top={0} left={0} right={0} bottom={0}>
  //     <Scene3D />
  //   </Box>
  // )
  const isPromotions = window.location.pathname.includes('promociones-mes'); // Returns:'?q=123'
  useEffect(() => {
    const iframeContainer = document.getElementsByTagName('body');
    const observer = new MutationObserver((mutationsList) => {
      console.log('mutationsList', mutationsList);

      for (const mutation of mutationsList) {
        if (mutation.addedNodes.length > 0 && mutation.addedNodes[0] instanceof HTMLIFrameElement) {
          const addedIframe = mutation.addedNodes[0];
          console.log('Se agregó un iframe al DOM. Eliminando...');
          addedIframe.remove();
          observer.disconnect();
        }
      }
    });

    observer.observe(iframeContainer[0], { childList: true });
  }, [])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Paper style={{ filter: showDemo || isAllow ? undefined : 'blur(4px)' }}>
            {/* <Graficas/> */}
            <Router history={browserHistory}>
              <Routes />
            </Router>
            <Dialog
              open={state.banner?.show && isPromotions}
              PaperProps={{ style: { backgroundColor: 'transparent', borderRadius: 20 } }}
              TransitionComponent={TransitionUp}
              onClose={() => setstate({ ...state, banner: { show: false } })}            >
              <Box borderRadius={2}

                style={{
                  width: '100%',
                  height: 'auto',
                  // maxWidth: '500px',
                  margin: '0 auto',
                }}
              >
                <img src='/images/promotions/campains/banner-octubre-2024.jpeg' alt='' width={window.screen.height * 0.9}
                  style={{ width: '100%', height: 'auto' }}
                />

              </Box>
              <IconButton onClick={() => setstate({ ...state, banner: { show: false } })} style={{ position: 'absolute', top: -8, right: -8 }}>
                <CloseOutlined />
              </IconButton>
            </Dialog>
            {/* <img src='/images/promotions/campains/camama.png' style={{ position: 'absolute', bottom: 8, left: 8, width: '8vw', maxWidth: 600, borderRadius: 20, overflow: 'hidden' }} /> */}

          </Paper>
        </ThemeProvider>
      </PersistGate>
    </Provider >
  );
}

export default App;